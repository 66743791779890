<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <wolk-private-data-alert />
      </v-col>
    </v-row>

    <v-row v-if="!loading && wolk">
      <v-col>
        <v-card>
          <v-card-title>
            Wolk {{ wolk.metaData.sleevePublicId }}
            <v-tooltip
              v-if="retourEnabled"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mx-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ getIconForRetourStatus(retourStatus) }}
                </v-icon>
              </template>
              <span>{{ humanizeRetourStatus(retourStatus) }}</span>
            </v-tooltip>
            <template v-if="wolk.customerIdentifier">
              (ID: {{ wolk.customerIdentifier }})
            </template>
            <v-spacer />
            <v-card-actions
              v-if="canSeeRetourButtons"
            >
              <v-btn
                v-if="canCancelRetour"
                class="mx-2"
                :loading="loadingRetour"
                @click="cancelRetourButtonHandler"
              >
                Retour annuleren
              </v-btn>
              <v-row class="ma-0">
                <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="600px"
                >
                  <template
                    #activator="{ on, attrs }"
                  >
                    <v-btn
                      v-if="retourStatus !== 'sent'"
                      color="primary"
                      v-bind="attrs"
                      :disabled="false"
                      v-on="on"
                      @click="prefillFields"
                    >
                      <template v-if="retourStatus === 'exchange' || retourStatus === 'retour'">
                        verzenden
                      </template>
                      <template v-if="retourStatus !== 'exchange' && retourStatus !== 'retour'">
                        omruilen / retour
                      </template>
                    </v-btn>
                    <v-btn
                      v-if="retourStatus === 'no retour status' && canSetToMaintenance"
                      color="primary"
                      class="mx-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="openMaintenanceWindow"
                    >
                      Noodzakelijk onderhoud
                    </v-btn>
                  </template>
                  <!-- template for retourNoExchange -->
                  <v-card v-if="dialogScreen === 'retourNoExchange'">
                    <v-card-title>
                      <span
                        class="text-h6"
                      >
                        Retour
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-form ref="retourForm">
                          <v-textarea
                            v-model="retourDescription"
                            label="Reden retour"
                            :rules="[rules.required]"
                          />
                          <v-checkbox
                            v-model="toSendPage"
                            label="Retourlabel aanmaken"
                          />
                        </v-form>
                        <p
                          class="red--text font-weight-bold"
                        >
                          Let op!
                          Weet je zeker dat je deze Wolk retour wilt sturen zonder vervanging?
                        </p>
                        <p class="red--text">
                          Bij ‘Bevestigen’ wordt deze Wolk gemarkeerd voor retour.
                        </p>
                        Wil je de Wolk direct versturen? Vink dan ‘Retourlabel aanmaken’ aan.
                        Liever later versturen? Doe dit dan, uiterlijk binnen 7 dagen,
                        via de overzichtspagina met alle heupairbags.
                        <br>
                        <br>
                        NB: Als je meer Wolks terugstuurt in één zending, dan heb je maar 1
                        label nodig.
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        :loading="loadingRetour"
                        @click="getPreviousDialogScreen"
                      >
                        Terug
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :loading="loadingRetour"
                        @click="exitDialog"
                      >
                        Annuleren
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :loading="loadingRetour"
                        @click="setExchange(toSendPage, 'retour')"
                      >
                        Bevestigen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- template for exchange -->
                  <v-card v-if="dialogScreen === 'exchange' && retourStatus === 'no retour status'">
                    <v-card-title>
                      <span
                        class="text-h6"
                      >
                        Reden van omruilen
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-radio-group v-model="exchangeRadio">
                          <v-radio
                            :label="`Defect`"
                            :value="'defect'"
                          />
                          <v-radio
                            label="Retour zonder vervanging"
                            value="retourNoExchange"
                          />
                          <v-radio
                            :label="`Verkeerde maat of model`"
                            :value="'size'"
                          />
                        </v-radio-group>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        @click="exitDialog"
                      >
                        Terug
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :disabled="!exchangeRadio"
                        @click="getNextDialogScreen(exchangeRadio)"
                      >
                        Doorgaan
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- template for size -->
                  <v-card v-if="dialogScreen === 'size'">
                    <v-card-title>
                      <span
                        class="text-h6"
                      >
                        Omruilen
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-radio-group
                              ref="sizeRadio"
                              v-model="replacementSizeRadio"
                              label="gewenste maat"
                              :rules="[rules.noSameSize]"
                            >
                              <v-radio
                                :label="`S`"
                                :value="`S`"
                              />
                              <v-radio
                                :label="`M`"
                                :value="`M`"
                              />
                              <v-radio
                                :label="`L`"
                                :value="`L`"
                              />
                              <v-radio
                                :label="`XL`"
                                :value="`XL`"
                              />
                            </v-radio-group>
                          </v-col>
                          <v-col>
                            <v-radio-group
                              v-model="replaceType"
                              label="Gewenst Wolk type"
                              :disabled="!replacementSizeRadio"
                            >
                              <v-radio
                                :label="`Belt`"
                                :value="'Belt'"
                              />
                              <v-radio
                                :label="`Shorts`"
                                :value="'Shorts'"
                              />
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        @click="getPreviousDialogScreen"
                      >
                        Terug
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :disabled="!replacementSizeRadio || !replaceType"
                        @click="getNextDialogScreen"
                      >
                        Doorgaan
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- template for receive -->
                  <v-card v-if="dialogScreen === 'receive'">
                    <v-card-title>
                      <span
                        class="text-h6"
                        style="word-break: break-word"
                      >
                        Adres waar vervangende Wolk naartoe gestuurd wordt
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-form
                        ref="exchangeForm"
                        autocomplete="on"
                      >
                        <v-text-field
                          v-model="tav"
                          label="Ter attentie van"
                          :rules="[rules.required]"
                          autocomplete="name"
                        />
                        <v-text-field
                          v-model="toLocation"
                          label="Locatie en/of afdeling"
                          :rules="[rules.required]"
                          autocomplete="organization"
                        />
                        <v-text-field
                          v-model="toStreet"
                          label="Straat"
                          :rules="[rules.required]"
                          autocomplete="street-address"
                        />
                        <v-text-field
                          v-model="toHouseNumber"
                          label="Huisnummer"
                          :rules="[rules.required, rules.houseNumber]"
                          autocomplete="address-line2"
                        />
                        <v-text-field
                          v-model="toCity"
                          label="Stad"
                          :rules="[rules.required]"
                          autocomplete="address-level2"
                        />
                        <v-text-field
                          v-model="toPostalCode"
                          label="Postcode"
                          :rules="[rules.required, rules.postCode]"
                          autocomplete="postal-code"
                        />
                        <v-select
                          v-model="toCountry"
                          :items="countries"
                          item-text="country"
                          item-value="abbr"
                          label="Land"
                          :rules="[rules.required]"
                          autocomplete="country"
                        />
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        @click="exitDialog"
                      >
                        Annuleren
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :disabled="
                          !tav
                            || !toLocation
                            || !toStreet
                            || !toHouseNumber
                            || !toCity
                            || !toPostalCode
                            || !toCountry"
                        @click="getNextDialogScreen('confirm')"
                      >
                        Doorgaan
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- template for receiveOverview -->
                  <v-card v-if="dialogScreen === 'receiveOverview'">
                    <v-card-title>
                      <span
                        class="text-h6"
                      >
                        Overzicht Wolk vervangen
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <br>
                      <p
                        v-if="retourReason === 'wrongSize'"
                        class="red--text font-weight-bold"
                      >
                        Let op! Weet je zeker dat je deze Wolk wilt omruilen?
                      </p>

                      <p
                        v-if="retourReason === 'defect'"
                        class="red--text font-weight-bold"
                      >
                        Let op! Weet je zeker dat deze Wolk defect is?
                      </p>

                      <p
                        v-if="retourReason === 'wrongSize'"
                        class="red--text"
                      >
                        Bij ‘Bevestigen’ versturen wij zo snel mogelijk een vervangend product
                        naar het onderstaande adres.
                        Annuleren of aanpassen van deze omruiling kan alleen nog op dezelfde dag.
                        Annuleer je de omruiling hierna?
                        Dan brengen we de kosten voor het verzenden
                        van het vervangende product bij je in rekening.
                      </p>
                      <p
                        v-if="retourReason === 'defect'"
                        class="red--text"
                      >
                        Bij ‘Bevestigen’ versturen wij zo snel mogelijk een
                        vervangend product naar het onderstaande adres.
                        Annuleren of aanpassen van deze defectmelding
                        (bijv. als de Wolk toch niet defect blijkt) kan alleen nog op dezelfde dag.
                        Annuleer je de retourmelding hierna?
                        Dan brengen we de kosten voor het verzenden van het
                        vervangende product bij je in rekening.
                      </p>

                      Wil je de Wolk direct versturen? Vink dan ‘Retourlabel aanmaken’ aan.
                      Liever later versturen? Doe dit dan, uiterlijk binnen 7 dagen,
                      via de overzichtspagina met alle heupairbags.
                      <br>
                      <br>
                      NB: Als je meer Wolks terugstuurt in één zending, dan heb je maar 1
                      label nodig.
                      <br>
                      <v-col>
                        <v-row
                          v-if="tav"
                        >
                          <v-col
                            cols="4"
                          >
                            <p class="font-weight-bold">
                              Ter attentie van:
                            </p>
                          </v-col>
                          <v-col
                            cols="8"
                          >
                            <p class="pl-4 font-weight-medium">
                              {{ tav }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="toLocation"
                        >
                          <v-col
                            cols="4"
                          >
                            <p class="font-weight-bold">
                              Locatie:
                            </p>
                          </v-col>
                          <v-col
                            cols="8"
                          >
                            <p class="pl-4 font-weight-medium">
                              {{ toLocation }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="toStreet
                            && toHouseNumber
                            && toCity
                            && toPostalCode
                            && toCountry"
                        >
                          <v-col
                            cols="4"
                          >
                            <p class="font-weight-bold">
                              Adres ontvanger:
                            </p>
                          </v-col>
                          <v-col
                            cols="8"
                          >
                            <p class="pl-4 font-weight-medium">
                              {{
                                `${toStreet} ${toHouseNumber},
                          ${toCity} ${toPostalCode} ${toCountry}`
                              }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="lastName && lastName.length > 0
                            && firstName && firstName.length > 0"
                        >
                          <v-col
                            cols="4"
                          >
                            <p class="font-weight-bold">
                              Aanvrager:
                            </p>
                          </v-col>
                          <v-col
                            cols="8"
                          >
                            <p class="pl-4 font-weight-medium">
                              {{ `${lastName} ${firstName}` }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="email && email.length > 0"
                        >
                          <v-col
                            cols="4"
                          >
                            <p class="font-weight-bold">
                              Email adres:
                            </p>
                          </v-col>
                          <v-col
                            cols="8"
                          >
                            <p class="pl-4 font-weight-medium">
                              {{ email }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="retourReason !== 'wrongSize'"
                        >
                          <v-col
                            cols="4"
                          >
                            <p class="font-weight-bold">
                              Reden omruiling:
                            </p>
                          </v-col>
                          <v-col
                            cols="8"
                          >
                            <p class="pl-4 font-weight-medium">
                              {{ getHumanizedRetourReason() }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="retourReason !== 'wrongSize'"
                        >
                          <v-col
                            cols="4"
                          >
                            <p
                              class="font-weight-bold"
                            >
                              Wolk vervangen:
                            </p>
                          </v-col>
                          <v-col
                            cols="8"
                          >
                            <p class="pl-4 font-weight-medium">
                              {{ replaceWolk ? 'ja' : 'nee' }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="replaceWolk
                            && replacementSizeRadio
                            && replacementSizeRadio.length > 0"
                        >
                          <v-col
                            cols="4"
                          >
                            <p class="font-weight-bold">
                              Gewenste maat:
                            </p>
                          </v-col>
                          <v-col
                            cols="8"
                          >
                            <p class="pl-4 font-weight-medium">
                              {{ replacementSizeRadio }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row
                          v-else-if="replaceWolk && defectSizeRadio && defectSizeRadio.length > 0"
                        >
                          <v-col
                            cols="4"
                          >
                            <p class="font-weight-bold">
                              Gewenste maat:
                            </p>
                          </v-col>
                          <v-col
                            cols="8"
                          >
                            <p class="pl-4 font-weight-medium">
                              {{ defectSizeRadio }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-checkbox
                          v-model="toSendPage"
                          label="Retourlabel aanmaken"
                        />
                      </v-col>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        :loading="loadingRetour"
                        @click="getPreviousDialogScreen"
                      >
                        Terug
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :loading="loadingRetour"
                        @click="exitDialog"
                      >
                        Annuleren
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :loading="loadingRetour"
                        @click="setExchange(toSendPage, 'exchange')"
                      >
                        Bevestigen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- template for selecting wolks -->
                  <v-card
                    v-if="dialogScreen === 'send'"
                  >
                    <v-card-title>
                      <span
                        class="text-h6"
                      >
                        Wolks verzenden
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        v-model="selected"
                        :headers="retourHeaders"
                        :options.sync="retourTableSort"
                        :items="retourWolks"
                        :items-per-page="20"
                        :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
                        :loading="loading"
                        show-select
                        item-key="metaData.sleevePublicId"
                      >
                        <template #[`item.metaData.sleevePublicId`]="{ item }">
                          <router-link :to="{ name: 'wolksShow', params: { id: item._id } }">
                            {{ item.metaData.sleevePublicId }}
                          </router-link>
                        </template>

                        <template #[`item.metaData.organizationTitle`]="{ item }">
                          <router-link
                            v-if="item.metaData &&
                              item.organizationId &&
                              item.metaData.organizationTitle"
                            :to="{ name: 'organizationsShow', params: { id: item.organizationId } }"
                          >
                            {{ item.metaData.organizationTitle }}
                          </router-link>
                        </template>

                        <template #[`item.metaData.locationTitle`]="{ item }">
                          <router-link
                            v-if="item.metaData && item.metaData.locationTitle"
                            :to="{ name: 'locationsShow', params: { id: item.locationId } }"
                          >
                            {{ item.metaData.locationTitle }}
                          </router-link>
                        </template>

                        <template #[`item.metaData.size`]="{ item }">
                          {{ item.metaData.size }}
                        </template>
                        <template #[`item.retour.status`]="{ item }">
                          {{ humanizeRetourStatus(item.retour.status) }}
                        </template>
                      </v-data-table>
                      <span
                        class="text-h6"
                      >
                        Adres afzender
                      </span>
                      <v-form
                        ref="sendForm"
                        autocomplete="on"
                      >
                        <v-text-field
                          v-model="fromStreet"
                          label="Straat"
                          :rules="[rules.required]"
                          autocomplete="street-address"
                        />
                        <v-text-field
                          v-model="fromHouseNumber"
                          label="Huisnummer"
                          :rules="[rules.required, rules.houseNumber]"
                          autocomplete="address-line2"
                        />
                        <v-text-field
                          v-model="fromCity"
                          label="Stad"
                          :rules="[rules.required]"
                          autocomplete="address-level2"
                        />
                        <v-text-field
                          v-model="fromPostalCode"
                          label="Postcode"
                          :rules="[rules.required, rules.postCode]"
                          autocomplete="postal-code"
                        />
                        <v-select
                          v-model="fromCountry"
                          :items="countries"
                          item-text="country"
                          item-value="abbr"
                          label="Land"
                          :rules="[rules.required]"
                          autocomplete="country"
                        />
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        @click="exitDialog"
                      >
                        Terug
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :disabled="!selected.length > 0 ||
                          !fromStreet ||
                          !fromHouseNumber ||
                          !fromCity ||
                          !fromPostalCode ||
                          !fromCountry"
                        @click="getNextDialogScreen"
                      >
                        Doorgaan
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- template for sendOverview -->
                  <v-card
                    v-if="dialogScreen === 'overview'"
                  >
                    <v-card-title>
                      Overzicht verzending
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="4"
                        >
                          <p class="font-weight-bold">
                            Ontvanger:
                          </p>
                        </v-col>
                        <v-col
                          cols="8"
                        >
                          <p class="pl-4 font-weight-medium">
                            {{ receiver }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="4"
                        >
                          <p class="font-weight-bold">
                            Adres afzender:
                          </p>
                        </v-col>
                        <v-col
                          cols="8"
                        >
                          <p class="pl-4 font-weight-medium">
                            {{
                              `${fromStreet} ${fromHouseNumber},
                          ${fromCity} ${fromPostalCode} ${fromCountry}`
                            }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="lastName && lastName.length > 0 && firstName && firstName.length > 0"
                      >
                        <v-col
                          cols="4"
                        >
                          <p class="font-weight-bold">
                            Aanvrager:
                          </p>
                        </v-col>
                        <v-col
                          cols="8"
                        >
                          <p class="pl-4 font-weight-medium">
                            {{ `${lastName} ${firstName}` }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="email && email.length > 0"
                      >
                        <v-col
                          cols="4"
                        >
                          <p class="font-weight-bold">
                            Email adres:
                          </p>
                        </v-col>
                        <v-col
                          cols="8"
                        >
                          <p class="pl-4 font-weight-medium">
                            {{ email }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <p class="font-weight-bold">
                            Geselecteerde wolks:
                          </p>
                        </v-col>
                      </v-row>
                      <v-data-table
                        v-model="selected"
                        class="mt-4"
                        :headers="retourHeaders"
                        :options.sync="retourTableSort"
                        :items="selectedRetourWolks"
                        :items-per-page="20"
                        :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
                        :loading="loading"
                        item-key="metaData.sleevePublicId"
                      >
                        <template #[`item.metaData.sleevePublicId`]="{ item }">
                          <router-link :to="{ name: 'wolksShow', params: { id: item._id } }">
                            {{ item.metaData.sleevePublicId }}
                          </router-link>
                        </template>

                        <template #[`item.metaData.organizationTitle`]="{ item }">
                          <router-link
                            v-if="item.metaData &&
                              item.organizationId &&
                              item.metaData.organizationTitle"
                          >
                            {{ item.metaData.organizationTitle }}
                          </router-link>
                        </template>

                        <template #[`item.metaData.locationTitle`]="{ item }">
                          <router-link
                            v-if="item.metaData && item.metaData.locationTitle"
                            :to="{ name: 'locationsShow', params: { id: item.locationId } }"
                          >
                            {{ item.metaData.locationTitle }}
                          </router-link>
                        </template>

                        <template #[`item.metaData.size`]="{ item }">
                          {{ item.metaData.size }}
                        </template>
                        <template #[`item.retour.status`]="{ item }">
                          {{ humanizeRetourStatus(item.retour.status) }}
                        </template>
                      </v-data-table>
                      <v-row>
                        <v-col>
                          <h3>Je ontvangt het retourlabel per mail</h3>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        :loading="loadingRetour"
                        @click="exitDialog"
                      >
                        Annuleren
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :loading="loadingRetour"
                        @click="sendWolks"
                      >
                        Bevestigen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- template for defect -->
                  <v-card v-if="dialogScreen === 'defect'">
                    <v-card-title>
                      <span
                        v-if="defectType === null"
                        class="text-h6"
                      >
                        Wat doet het lampje?
                      </span>
                      <span
                        v-if="defectType !== null"
                        class="text-h6"
                      >
                        Doorloop de volgende stappen.
                      </span>
                    </v-card-title>
                    <v-card-text

                      v-if="defectType === null"
                    >
                      Zorg dat de wolk niet aan de lader zit, sluit het klittenband indien het een
                      Belt betreft, en beweeg de Wolk.
                      <br>
                      <br>
                      <p class="red--text font-weight-bold">
                        Let op! Voor een juiste beoordeling van de kleur van het lampje dien je het
                        lampje altijd van bovenaf te bekijken en niet vanaf de zijkant.
                      </p>
                      <template
                        v-if="defectType === null"
                      >
                        <v-row
                          class="pt-2"
                        >
                          <v-col>
                            <v-btn
                              @click="defectType = 'A'"
                            >
                              A. Lampje brandt <br> niet
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              @click="defectType = 'B'"
                            >
                              B. Lampje brandt <br> continu rood
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              @click="setDefectType('C')"
                            >
                              C. Overig
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                    </v-card-text>

                    <defect-a
                      v-if="defectType === 'A'"
                      ref="defectA"
                      @defect="handleDefect"
                    />
                    <defect-b
                      v-if="defectType === 'B'"
                      ref="defectB"
                      @defect="handleDefect"
                    />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        @click="getPreviousDefectQuestion"
                      >
                        Terug
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        @click="exitDialog"
                      >
                        Annuleren
                      </v-btn>
                      <v-btn
                        v-if="defect !== false"
                        color="primary"
                        text
                        :disabled="defect !== true && defect !== undefined"
                        @click="getNextDialogScreen"
                      >
                        Doorgaan
                      </v-btn>
                      <v-btn
                        v-if="defect === false"
                        color="primary"
                        text
                        @click="exitDialog"
                      >
                        Bevestigen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- template for describe defect -->
                  <v-card v-if="dialogScreen === 'describeDefect'">
                    <v-card-title>
                      <span class="text-h6">
                        Omschrijving defect
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        Type defect: {{ defectType }}
                        <v-form ref="defectForm">
                          <v-textarea
                            v-model="defectDescription"
                            :disabled="retourStatus === 'maintenance'"
                            label="Omschrijving defect"
                            :rules="[rules.required]"
                          />
                          <v-textarea
                            v-model="defectOrigin"
                            :disabled="retourStatus === 'maintenance'"
                            label="Hoe is volgens jou dit defect ontstaan? (optioneel)"
                          />
                          <v-row>
                            <v-col>
                              <v-radio-group
                                v-model="replaceWolk"
                                justify="2"
                                label="Wolk vervangen"
                              >
                                <v-radio
                                  :label="`Ja`"
                                  :value="true"
                                />
                                <v-radio
                                  :label="`Nee`"
                                  :value="false"
                                />
                              </v-radio-group>
                            </v-col>
                            <v-col>
                              <v-radio-group
                                v-model="replaceType"
                                label="Gewenst Wolk type"
                                :disabled="!replaceWolk"
                              >
                                <v-radio
                                  :label="`Belt`"
                                  :value="'Belt'"
                                />
                                <v-radio
                                  :label="`Shorts`"
                                  :value="'Shorts'"
                                />
                              </v-radio-group>
                            </v-col>
                            <v-col>
                              <v-radio-group
                                v-model="defectSizeRadio"
                                justify="2"
                                label="gewenste maat"
                                :disabled="!replaceWolk || !replaceType"
                              >
                                <v-radio
                                  :label="`S`"
                                  :value="`S`"
                                />
                                <v-radio
                                  :label="`M`"
                                  :value="`M`"
                                />
                                <v-radio
                                  :label="`L`"
                                  :value="`L`"
                                />
                                <v-radio
                                  :label="`XL`"
                                  :value="`XL`"
                                />
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        @click="getPreviousDialogScreen"
                      >
                        Terug
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        @click="exitDialog"
                      >
                        Annuleren
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :disabled="
                          !defectDescription
                            || (replaceWolk === null)
                            || (replaceWolk && !defectSizeRadio)"
                        @click="getNextDialogScreen"
                      >
                        Doorgaan
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- template for setting maintenance -->
                  <v-card v-if="dialogScreen === 'setMaintenance'">
                    <v-card-title>
                      <span class="text-h6">
                        Wolk op noodzakelijk onderhoud zetten
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        Type defect: {{ defectType }}
                        <v-form ref="defectForm">
                          <v-textarea
                            v-model="defectDescription"
                            :disabled="retourStatus === 'maintenance'"
                            label="Omschrijving defect"
                            :rules="[rules.required]"
                          />
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        @click="exitDialog"
                      >
                        Annuleren
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :disabled="!defectDescription"
                        @click="setMaintenance"
                      >
                        Bevestigen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-card-actions>
          </v-card-title>
          <v-card-text>
            <p v-if="wolk.organizationId">
              Van

              <router-link
                :to="{ name: 'organizationsShow', params: { id: wolk.organizationId }}"
                v-text="wolk.metaData.organizationTitle"
              />

              <template v-if="wolk.locationId">
                locatie

                <router-link
                  :to="{ name: 'locationsShow', params: { id: wolk.locationId }}"
                  v-text="wolk.metaData.locationTitle"
                />
              </template>
            </p>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-simple-table>
                  <template #default>
                    <tbody>
                      <tr
                        v-for="item in specs"
                        :key="item.key"
                      >
                        <td>
                          {{ item.humanizedName }}
                        </td>

                        <td>
                          {{ item.value }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-card
                  v-if="wolk.currentStatusInfo"
                  class="mt-3"
                >
                  <v-card-title primary-title>
                    Laatst ontvangen status
                  </v-card-title>

                  <v-simple-table>
                    <template #default>
                      <tbody>
                        <tr
                          v-for="item in statusSpecs"
                          :key="item.key"
                        >
                          <td>
                            {{ item.humanizedName }}
                          </td>

                          <td>
                            {{ item.value }}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Software
                          </td>

                          <td>
                            <firmware-version-chip :status-info="wolk.currentStatusInfo || {}" />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-expansion-panels
                  v-model="wolkPanelStates"
                  multiple
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Locatiegeschiedenis
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-card-text
                        v-if="wolkLocationAssignmentsLoading"
                        class="pa-0"
                      >
                        <h3>
                          Aan het laden.
                        </h3>
                      </v-card-text>

                      <v-card-text
                        v-else
                        class="pa-0"
                      >
                        <span v-if="wolkLocationAssignments.length === 0">
                          Er is nog geen locatiegeschiedenis om weer te geven
                        </span>

                        <v-list-item
                          v-for="wolkLocationAssignment in wolkLocationAssignments"
                          :key="wolkLocationAssignment.createdAt"
                          three-line
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ wolkLocationAssignment.locationTitle }}
                            </v-list-item-title>

                            <v-list-item-subtitle>
                              <!-- eslint-disable-next-line -->
                              Aangemaakt op {{ getHumanizedDate(wolkLocationAssignment.createdAt) }}
                              door {{ wolkLocationAssignment.createdBy }}.
                            </v-list-item-subtitle>

                            <v-list-item-subtitle v-if="wolkLocationAssignment.stoppedAt">
                              Gestopt op {{ getHumanizedDate(wolkLocationAssignment.stoppedAt) }}
                              door {{ wolkLocationAssignment.stoppedBy }}.
                            </v-list-item-subtitle>

                            <v-list-item-subtitle />
                          </v-list-item-content>
                        </v-list-item>
                      </v-card-text>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <v-row v-if="wolk && wolk.currentSleeveAssignment">
              <v-col>
                <analytics-v3-block
                  v-if="$store.state.auth.type"
                  scope="sleeveAssignment"
                  :scope-id="wolk.currentSleeveAssignment._id"
                  show-steps
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-switch
              v-if="!viewOnlyUser"
              v-model="changeCustomerIdentifierVisible"
              label="ID aanpassen"
            />

            <div v-if="changeCustomerIdentifierVisible">
              <v-alert
                text
                color="info"
              >
                <h3 class="headline mb-2">
                  Wat is dit?
                </h3>

                <div>
                  De unieke ID kan je toewijzen aan de Wolk.
                  Je kunt hier bijvoorbeeld het kamernummer of de naam van de cliënt gebruiken.
                  Daarmee kan je makkelijk herkennen aan wie de Wolk toebehoort.
                  Vergeet niet de ID te veranderen wanneer de Wolk wisselt van gebruiker.
                </div>

                <v-divider
                  class="my-4 info"
                  style="opacity: 0.22"
                />

                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="newCustomerIdentifier"
                      label="Nieuw ID"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-btn
                      outlined
                      color="info"
                      class="mr-2"
                      @click="changeCustomerIdentifierVisible = false"
                    >
                      Annuleren
                    </v-btn>

                    <v-btn
                      :disabled="!newCustomerIdentifier"
                      :loading="loading"
                      outlined
                      color="success"
                      @click="updateCustomerIdentifier"
                    >
                      Opslaan
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="wolk.customerIdentifier">
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-btn
                      outlined
                      color="error"
                      @click="removeCustomerIdentifier"
                    >
                      Verwijder ID
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </div>

            <sleeve-assignments-change-location
              v-if="wolk && wolk.currentSleeveAssignment && locations.loaded && !viewOnlyUser"
              :wolk-id="wolk._id"
              :sleeve-assignment-id="wolk.currentSleeveAssignment._id"
              :current-location-id="wolk.locationId"
              :locations="locations.value"
              @update:wolk="resetAndReload"
            />

            <sleeve-assignments-change-notification-subscriptions
              v-if="canEditNotificationSubscriptions"
              :sleeve-assignment-id="wolk.currentSleeveAssignment._id"
              :organization-id="wolk.currentSleeveAssignment.organizationId"
              @update:wolk="resetAndReload"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="$store.state.dev.enabled">
      <v-col>
        <v-card>
          <v-card-title>
            Invalid events
          </v-card-title>

          <v-card-text v-if="invalidEvents.length === 0">
            <v-btn
              :loading="invalidEventsLoading"
              @click="loadInvalidEvents"
            >
              Load
            </v-btn>
          </v-card-text>

          <v-card-text
            v-for="(invalidEvent, index) in invalidEvents"
            v-else
            :key="index"
          >
            <v-row>
              <v-col cols="12">
                <h3>Errors</h3>

                <ul>
                  <li
                    v-for="(error, errorIndex) in invalidEvent.errors"
                    :key="`${index}-error-${errorIndex}`"
                  >
                    {{ error }}
                  </li>
                </ul>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <h3>From</h3>

                <pre>{{ invalidEvent.from }}</pre>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <h3>To</h3>

                <pre>{{ invalidEvent.to }}</pre>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="canCreateEvents">
      <v-col>
        <v-card class="mx-auto">
          <v-card-title
            class="blue-grey white--text"
          >
            <span class="title">
              Genereer events
            </span>

            <v-spacer />
          </v-card-title>
          <v-spacer />
          <v-card-text>
            <v-btn
              class="ma-2"
              @click="createWolkEvent('fallEvent', '')"
            >
              Val
            </v-btn>

            <v-btn
              class="ma-2"
              @click="createWolkEvent('postFall', '')"
            >
              PostFall
            </v-btn>
            <v-btn
              class="ma-2"
              @click="createWolkEvent('fallWithoutActivation', '')"
            >
              Val zonder activatie
            </v-btn>
            <v-btn
              class="ma-2"
              @click="createWolkEvent('batteryLow', '')"
            >
              Heartbeat (Battery Low)
            </v-btn>
            <v-btn
              class="ma-2"
              @click="createWolkEvent('heartbeat', '')"
            >
              Heartbeat
            </v-btn>
            <v-btn
              v-if="false"
              class="ma-2"
              @click="createWolkEvent('heartbeat', 'ML:1')"
            >
              Heartbeat (ML:1)
            </v-btn>
            <v-btn
              class="ma-2"
              @click="createWolkEvent('replaceCartridge', '')"
            >
              Replace Cartridge
            </v-btn>
            <v-btn
              class="ma-2"
              @click="createWolkEvent('error', '')"
            >
              Error
            </v-btn>
            <v-btn
              class="ma-2"
              @click="createWolkEvent('tap', '')"
            >
              Tap
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="mx-auto">
          <v-card-title
            class="blue-grey white--text"
          >
            <span class="title">
              Vallen
            </span>

            <v-spacer />

            <v-btn
              class="mr-2"
              outlined
              color="white"
              dark
              depressed
              @click="showingHeartbeats = !showingHeartbeats"
            >
              {{ showingHeartbeats ? 'Verberg heartbeats' : 'Toon heartbeats' }}
            </v-btn>

            <v-btn
              v-if="$store.state.dev.enabled"
              outlined
              color="white"
              dark
              depressed
              @click="showingFullData = !showingFullData"
            >
              {{ showingFullData ? 'Verberg data' : 'Toon data' }}
            </v-btn>
          </v-card-title>

          <v-card-text class="py-0">
            <v-timeline dense>
              <v-slide-x-reverse-transition
                group
                hide-on-leave
              >
                <v-timeline-item
                  v-for="event in visibleEvents"
                  :key="event._id"
                  :color="event.displayData.color"
                  :icon="event.displayData.icon"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    :color="event.displayData.color"
                    :dark="event.displayData.inversTextColor"
                  >
                    {{ getHumanizedDate(event.createdAt) }}<br>

                    <span v-if="event.type === 'heartbeat'">
                      Heartbeat<br><br>

                      {{ event.displayData.title }}
                    </span>

                    <span v-else-if="event.type === 'postFall'">
                      Val zonder activatie
                    </span>

                    <span v-else>
                      Val
                    </span>

                    <pre v-if="showingFullData">
                        {{ event }}
                      </pre>
                  </v-alert>
                </v-timeline-item>
              </v-slide-x-reverse-transition>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import DefectA from '@/views/wolks/DefectA.vue';
import DefectB from '@/views/wolks/DefectB.vue';

import {
  createBreadcrumbs,
  getHumanizedBatteryPercentage,
  getHumanizedDate,
  getStatusInfo,
} from '../../helpers/humanizer';

import AnalyticsV3Block from '../../components/AnalyticsV3Block.vue';
import FirmwareVersionChip from '../../components/FirmwareVersionChip.vue';
import sleeveAssignmentsChangeLocation from '../../components/sleeveAssignments/ChangeLocation.vue';
import sleeveAssignmentsChangeNotificationSubscriptions
from '../../components/sleeveAssignments/ChangeNotificationSubscriptions.vue';
import WolkPrivateDataAlert from '../../components/WolkPrivateDataAlert.vue';

const getDisplayData = (event) => {
  const icon = event.type === 'heartbeat' ? 'mdi-heart' : 'mdi-alert';

  let statusInfo = {
    key: 'fall',
    title: 'Val',
    color: '#FF4500',
    inversTextColor: false,
  };

  if (event.type === 'heartbeat') {
    statusInfo = getStatusInfo(event.statusInfo ? event.statusInfo.parsedStatusKey : null);
  }

  return {
    icon,
    ...statusInfo,
  };
};

export default {
  name: 'WolkShow',
  components: {
    DefectA,
    DefectB,
    AnalyticsV3Block,
    FirmwareVersionChip,
    sleeveAssignmentsChangeLocation,
    sleeveAssignmentsChangeNotificationSubscriptions,
    WolkPrivateDataAlert,
  },
  data() {
    return {
      loading: false,
      invalidEventsLoading: false,
      wolkLocationAssignmentsLoading: false,
      showingHeartbeats: false,
      showingFullData: false,
      changeCustomerIdentifierVisible: false,
      newCustomerIdentifier: '',
      wolk: {
        _id: null,
      },
      analyticsData: null,
      accumulatedIsValid: false,
      smsNotificationsEnabled: null,
      retourEnabled: null,
      events: [],
      invalidEvents: [],
      wolkLocationAssignments: [],
      locations: {
        loaded: false,
        value: [],
      },
      // retour data
      dialog: false,
      retourStatus: 'no retour status',
      dialogScreen: 'exchange',
      previousDialogScreen: null,
      replacementSizeRadio: null,
      defectSizeRadio: null,
      defectType: null,
      exchangeRadio: null,
      retourSize: null,
      defectDescription: null,
      defectOrigin: null,
      replaceWolk: null,
      replaceType: null,
      retourReason: null,
      defect: null,
      toSendPage: null,
      rules: {
        required: (v) => !!v || 'Verplicht',
        number: (v) => Number.isInteger(v) || 'Moet numeriek zijn',
        postCode: (v) => /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(v) || 'Geen geldige postcode',
        houseNumber: (v) => /^.{0,10}$/.test(v) || 'Maximaal aantal karakters of cijfers is 10',
        noSameSize: () => true,
      },
      lazyRules: {
        required: (v) => !!v || 'Verplicht',
        number: (v) => Number.isInteger(v) || 'Moet numeriek zijn',
        postCode: (v) => /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(v) || 'Geen geldige postcode',
        houseNumber: (v) => /^.{0,10}$/.test(v) || 'Maximaal aantal karakters of cijfers is 10',
        noSameSize: () => !((this.replaceType === this.wolk.type)
          && (this.replacementSizeRadio === this.defectSizeRadio)) || 'Zelfde maat kan enkel gekozen worden bij een verschillend type',
      },
      defectChecklist: [],
      replacementAddress: null,
      selected: [],
      retourTableSort: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['metaData.locationTitle'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
      },
      firstName: null,
      lastName: null,
      email: null,
      sendAddress: null,
      receiver: 'Wolk B.V.',
      receiverAddress: 'Anna van Hannoverstraat 4 2595 BJ Den Haag, Nederland',
      wolks: [],
      fromStreet: null,
      fromHouseNumber: null,
      fromCity: null,
      fromPostalCode: null,
      fromCountry: 'NL',
      toStreet: null,
      toHouseNumber: null,
      toCity: null,
      toPostalCode: null,
      toCountry: 'NL',
      timestamp: null,
      countries: [
        {
          country: 'Nederland',
          abbr: 'NL',
        },
      ],
      toLocation: null,
      tav: null,
      loadingRetour: false,
      retourDescription: null,
    };
  },
  computed: {
    viewOnlyUser() {
      return this.user?.isViewOnly;
    },
    breadcrumbs() {
      return createBreadcrumbs(
        this.$store.state.app.dashboardHomeName,
        [
          {
            text: 'Wolks',
            disabled: false,
            exact: true,
            to: { name: 'wolks' },
          },
          {
            text: `Wolk ${(this.wolk.metaData || { sleevePublicId: '' }).sleevePublicId}`,
            disabled: true,
            exact: true,
            to: { name: 'wolks', params: { id: this.$route.params.id } },
          },
        ],
        false,
      );
    },
    canCreateEvents() {
      if (window.location.origin.includes('staging')) {
        return (this.$store.getters['auth/isAdmin'] || this.$store.getters['auth/isAdminUser']);
      }

      if (!this.$store.getters['auth/isAdmin']) {
        return false;
      }
      const devSettingsString = localStorage.getItem('devSettings');
      if (!devSettingsString) {
        return false;
      }
      return JSON.parse(devSettingsString)?.enabled;
    },
    canCancelRetour() {
      return this.retourStatus === 'sent'
        && window.location.origin.includes('staging')
        && this.$store.getters['auth/isAdmin'];
    },
    canSetToMaintenance() {
      return this.$store.getters['auth/isAdmin'];
    },
    canSeeRetourButtons() {
      return !(!this.$store.getters['auth/isAdmin'] && !this.$store.getters['auth/isAdminUser']) && this.retourEnabled;
    },
    canEditNotificationSubscriptions() {
      if (this.user?.isViewOnly) {
        return false;
      }

      return this.wolk
        && this.wolk.currentSleeveAssignment
        && this.smsNotificationsEnabled;
    },
    wolkPanelStates: {
      get() {
        const {
          locationHistoryVisible,
        } = this.$store.state.app.wolkPanelStates;

        const openValues = [];
        if (locationHistoryVisible) {
          openValues.push(0);
        }

        return openValues;
      },
      set(value) {
        const newLocationHistoryVisible = value.includes(0);

        this.$store.commit('app/wolkPanelStates', {
          locationHistoryVisible: newLocationHistoryVisible,
        });
      },
    },
    hasValidStats() {
      if (!this.accumulatedIsValid) {
        return false;
      }

      return !!this.wolk.sleeveAssignmentStatisticsGroups;
    },
    specs() {
      if (!this.wolk) {
        return [];
      }

      return [
        {
          key: 'sleeveId',
          humanizedName: 'Wolk nr.',
          value: this.wolk.metaData.sleevePublicId,
        },
        {
          key: 'customerIdentifier',
          humanizedName: 'ID',
          value: this.wolk.customerIdentifier || 'Niet opgegeven',
        },
        {
          key: 'size',
          humanizedName: 'Maat',
          value: this.wolk.metaData.size,
        },
        {
          key: 'type',
          humanizedName: 'Type',
          value: this.wolk.type,
        },
      ];
    },
    statusSpecs() {
      if (!this.wolk) {
        return [];
      }

      let statusKey = null;
      let statusCreatedAt = null;
      if (this.wolk.currentStatusInfo) {
        statusKey = this.wolk.currentStatusInfo.parsedStatusKey;
        statusCreatedAt = this.wolk.currentStatusInfo.createdAt;
      }

      return [
        {
          key: 'statusReceivedAt',
          humanizedName: 'Ontvangen op',
          value: getHumanizedDate(statusCreatedAt),
        },
        {
          key: 'status',
          humanizedName: 'Status',
          value: getStatusInfo(statusKey).title,
        },
        {
          key: 'batteryPercentage',
          humanizedName: 'Batterij',
          value: getHumanizedBatteryPercentage(this.wolk, false).name,
        },
      ];
    },
    visibleEvents() {
      const convertedEvents = this.events.map((e) => ({
        ...e,
        displayData: getDisplayData(e),
      }));

      if (!this.showingHeartbeats) {
        if (this.wolk.type === 'Shorts') {
          return convertedEvents.filter((e) => e.type === 'fall' || e.type === 'postFall');
        }
        if (this.wolk.type === 'Belt') {
          return convertedEvents.filter((e) => e.type === 'fall');
        }
      }

      return convertedEvents;
    },
    retourHeaders() {
      return [
        { text: 'Wolknr.', value: 'metaData.sleevePublicId', sortable: false },
        { text: 'ID', value: 'customerIdentifier', sortable: false },
        { text: 'Locatie', value: 'metaData.locationTitle', sortable: true },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Maat', value: 'metaData.size', sortable: false },
        { text: 'Retour', value: 'retour.status', sortable: true },
      ];
    },
    retourWolks() {
      return this.wolks.filter((w) => w.retour && (w.retour.status === 'exchange' || w.retour.status === 'retour'));
    },
    selectedRetourWolks() {
      return this.selected
        .filter((value, index, self) => self.findIndex((v) => v._id === value._id) === index);
    },
  },
  watch: {
    showingHeartbeats(newVal) {
      if (newVal) {
        this.loadEvents(['heartbeat']);
        return;
      }

      this.loadEvents(['fall', 'postFall']);
    },
  },
  beforeMount() {
    this.loadAll();
  },
  methods: {
    async resetAndReload() {
      this.newCustomerIdentifier = '';
      this.changeCustomerIdentifierVisible = false;

      await this.loadAll();
    },
    async loadAll() {
      if (this.$store.state.myAccount.type === 'user') {
        await this.loadUser();
      }
      await this.loadWolk();
      await this.loadWolks();
      await this.loadLocations();
      await this.loadWolkLocationAssignments();
    },
    getHumanizedDate(millis) {
      return getHumanizedDate(millis);
    },
    selectLocation(id) {
      this.newLocationId = id;
    },
    getStatusInfo(key) {
      return getStatusInfo(key);
    },
    updateCustomerIdentifier() {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/${this.$route.params.id}`;
      this.loading = true;

      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'patch',
        data: {
          customerIdentifier: this.newCustomerIdentifier,
        },
      })
        .then(this.resetAndReload);
    },
    loadLocations() {
      const url = `${config.VUE_APP_API_BASE_URL}/locations`;
      const { organizationId } = this.wolk;

      if (!organizationId) {
        return Promise.reject(Error('Can not load locations when organization is not set.'));
      }

      return axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        params: {
          organizationId,
        },
      })
        .then((response) => {
          this.locations.value = response.data.locations;
          this.locations.loaded = true;
        });
    },
    loadWolk() {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/${this.$route.params.id}`;
      this.loading = true;

      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.wolk = response.data.wolk;
          this.analyticsData = response.data.analyticsData;
          this.accumulatedIsValid = response.data.accumulatedIsValid;
          this.smsNotificationsEnabled = response.data.smsNotificationsEnabled;
          this.retourEnabled = response.data.retourEnabled;
          this.setRetourVars();
        })
        .finally(() => {
          this.loadEvents(['fall', 'postFall']);
          this.loading = false;
        });
    },
    loadWolks() {
      const onlineWolkCutOff = new Date().getTime() - (1000 * 60 * 10);
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/wolks`;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
        params: { retourStatus: ['exchange', 'retour'] },
      })
        .then((response) => {
          if (this.$store.getters['myAccount/isNonAdminUser']) {
            const locationIds = this
              .$store
              .state
              .myAccount
              .userMetaData
              .locations
              .map((l) => l._id);

            this.wolks = response
              .data
              .wolks
              .filter((w) => locationIds.includes(w.locationId))
              .map((w) => ({
                ...w,
                online: w.currentStatusInfo
                  && w.currentStatusInfo.createdAt
                  && w.currentStatusInfo.createdAt > onlineWolkCutOff,
              }));

            return;
          }

          this.wolks = response.data.wolks.map((w) => ({
            ...w,
            online: w.currentStatusInfo
              && w.currentStatusInfo.createdAt
              && w.currentStatusInfo.createdAt > onlineWolkCutOff,
          }));
          const { _id: wolkId } = this.wolk;
          const filtered = this.wolks.filter((w) => w._id === wolkId);
          if (filtered.length > 0) {
            this.selected.push(filtered[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadEvents(type) {
      const url = `${config.VUE_APP_API_BASE_URL}/events`;

      if (!this.wolk.currentSleeveAssignment) {
        return Promise.resolve();
      }

      this.loading = true;

      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
        params: {
          sleeveAssignmentId: this.wolk.currentSleeveAssignment._id,
          type,
        },
      })
        .then((response) => {
          this.events = response.data.events;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadInvalidEvents() {
      this.invalidEventsLoading = true;

      const url = `${config.VUE_APP_API_BASE_URL}/wolks/${this.wolk._id}/invalid-events`;

      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.invalidEvents = response.data.invalidEvents;
        })
        .finally(() => {
          this.invalidEventsLoading = false;
        });
    },
    loadWolkLocationAssignments() {
      this.wolkLocationAssignmentsLoading = true;

      const url = `${config.VUE_APP_API_BASE_URL}/wolks/${this.wolk._id}/location-assignments`;

      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.wolkLocationAssignments = response.data.wolkLocationAssignments;
        })
        .finally(() => {
          this.wolkLocationAssignmentsLoading = false;
        });
    },
    removeCustomerIdentifier() {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/${this.$route.params.id}`;
      this.loading = true;

      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'patch',
        data: {
          customerIdentifier: null,
        },
      })
        .then(this.resetAndReload);
    },
    createWolkEvent(type, testVariables) {
      this.wolkLocationAssignmentsLoading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/${this.$route.params.id}/event`;
      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'post',
        data: {
          id: this.$route.params.id,
          timestamp: Date.now(),
          type,
          testVariables,
        },
      }).then(() => {
        this.$store.commit('app/snackbarText', {
          text: 'Event aangemaakt.',
          visible: true,
        });
        this.$store.commit('app/snackbarSuccess', true);
      }).catch((error) => {
        let { message } = error;
        if (error.response) {
          message = error.response.data.message;
        }
        this.$store.commit('app/snackbarText', {
          text: `Kon event niet aanmaken: ${message}.`,
          visible: true,
        });
        this.$store.commit('app/snackbarSuccess', false);
      });
    },
    getNextDialogScreen(value) {
      if (this.retourStatus === 'exchange' || this.retourStatus === 'retour') {
        this.previousDialogScreen = 'send';
        if (!this.$refs.sendForm.validate()) {
          return;
        }
        this.dialogScreen = 'overview';
        return;
      }
      this.previousDialogScreen = this.dialogScreen;
      if (!this.dialogScreen) {
        this.dialogScreen = 'exchange';
        return;
      }

      if (this.dialogScreen === 'exchange') {
        if (value === 'size') {
          this.replacementSizeRadio = null;
          this.replaceType = null;
        }
        this.dialogScreen = value;
        return;
      }

      if (this.dialogScreen === 'defect') {
        if (this.retourStatus === 'maintenance') {
          this.retourReason = 'maintenance';
        } else {
          this.retourReason = 'defect';
        }
        this.dialogScreen = 'describeDefect';
        return;
      }

      if (this.dialogScreen === 'describeDefect') {
        if (!this.$refs.defectForm.validate()) {
          return;
        }
        if (this.replaceWolk) {
          this.dialogScreen = 'receive';
          return;
        }
        this.dialogScreen = 'receiveOverview';
        return;
      }

      if (this.dialogScreen === 'size') {
        const tempRules = this.rules;
        this.rules = this.lazyRules;
        this.$nextTick(() => {
          if (!this.$refs.sizeRadio.validate()) {
            this.rules = tempRules;
            return;
          }
          this.rules = tempRules;
          this.retourReason = 'wrongSize';
          this.dialogScreen = 'receive';
        });
        return;
      }

      if (this.dialogScreen === 'receive') {
        if (value === 'send') {
          if (!this.$refs.exchangeForm.validate()) {
            return;
          }
          this.fromStreet = this.toStreet;
          this.fromHouseNumber = this.toHouseNumber;
          this.fromCity = this.toCity;
          this.fromPostalCode = this.toPostalCode;
          this.fromCountry = this.toCountry;
          this.dialogScreen = 'send';
          return;
        }
        if (value === 'confirm') {
          if (!this.$refs.exchangeForm.validate()) {
            return;
          }
          this.dialogScreen = 'receiveOverview';
          return;
        }
      }

      if (this.dialogScreen === 'send') {
        if (!this.$refs.sendForm.validate()) {
          return;
        }
        this.dialogScreen = 'overview';
        return;
      }

      if (this.dialogScreen === 'receiveOverview') {
        this.dialogScreen = 'send';
      }
    },
    getPreviousDialogScreen() {
      if (!this.dialogScreen) {
        this.dialogScreen = 'exchange';
        return;
      }
      if (this.dialogScreen === 'size') {
        this.dialogScreen = 'exchange';
        return;
      }
      if (this.dialogScreen === 'defect') {
        this.defectType = null;
        this.dialogScreen = 'exchange';
        return;
      }
      if (this.dialogScreen === 'describeDefect') {
        this.defectType = null;
        this.dialogScreen = 'defect';
        return;
      }
      if (this.dialogScreen === 'send') {
        this.dialogScreen = 'size';
      }
      if (this.dialogScreen === 'retourNoExchange') {
        this.dialogScreen = 'exchange';
      }
      if (this.dialogScreen === 'receiveOverview') {
        this.dialogScreen = this.previousDialogScreen;
      }
    },
    exitDialog() {
      if (this.retourStatus === 'exchange' || this.retourStatus === 'retour') {
        this.dialogScreen = 'send';
      } else if (this.retourStatus === 'maintenance') {
        this.dialogScreen = 'describeDefect';
      } else {
        this.dialogScreen = 'exchange';
      }
      this.defectSizeRadio = this.wolk.metaData.size;
      this.exchangeRadio = null;
      this.defectType = null;
      this.dialog = false;
      this.defect = null;
    },
    async setExchange(toSendPage, status) {
      this.loadingRetour = true;
      if (this.dialogScreen === 'describeDefect') {
        if (!this.$refs.defectForm.validate()) {
          this.loadingRetour = false;
          return;
        }
      }
      if (this.dialogScreen === 'retourNoExchange') {
        if (!this.$refs.retourForm.validate()) {
          this.loadingRetour = false;
          return;
        }
      }
      this.fromStreet = this.toStreet;
      this.fromHouseNumber = this.toHouseNumber;
      this.fromCity = this.toCity;
      this.fromPostalCode = this.toPostalCode;
      this.fromCountry = this.toCountry;
      const previousStatus = this.retourStatus;
      const previousTimestamp = this.timestamp;
      this.retourStatus = status;
      if (this.retourReason === 'defect' || this.retourReason === 'maintenance') {
        this.retourSize = this.defectSizeRadio;
      } else {
        this.retourSize = this.replacementSizeRadio;
      }
      if (this.retourReason === 'wrongSize') {
        this.replaceWolk = true;
      }
      if (!this.replaceWolk) {
        this.retourSize = null;
      }
      await this.patchWolkRetour({
        timestamp: Date.now(),
        status: this.retourStatus,
        reason: this.retourReason,
        replaceWolk: this.replaceWolk,
        replaceType: this.replaceType,
        replacementSize: this.retourSize,
        defectDescription: this.defectDescription,
        retourDescription: this.retourDescription,
        defectOrigin: this.defectOrigin,
        defectType: this.defectType,
        defectHistory: this.defectHistory,
        user: {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          organizationId: this.organizationId,
        },
        toAddress: {
          tav: this.tav,
          location: this.location,
          toStreet: this.toStreet,
          toHouseNumber: this.toHouseNumber,
          toCity: this.toCity,
          toPostalCode: this.toPostalCode,
          toCountry: this.toCountry,
          toLocation: this.toLocation,
        },
      }).then(async () => {
        await this.sendToOrderSystem().catch(async (error) => {
          // Error in order system => revert status
          await this.patchWolkRetour({
            timestamp: previousTimestamp || null,
            status: previousStatus,
          });
          this.retourStatus = previousStatus;
          this.$store.commit('app/snackbarText', {
            text: `Kon Wolk niet omruilen: ${error.message}`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
        });
        if (!toSendPage) {
          this.exitDialog();
        }
      });
      this.loadWolk();
      this.loadWolks();
      this.loadingRetour = false;
      this.dialogScreen = 'send';
    },
    humanizeRetourStatus(status) {
      if (status === 'exchange') {
        if (this.retourReason === 'maintenance') {
          return 'onderhoud - omruilen';
        }
        return 'omruilen';
      }
      if (status === 'sent') {
        return 'verzonden';
      }
      if (status === 'maintenance') {
        return 'onderhoud';
      }
      if (status === 'retour') {
        return 'retour';
      }
      return '';
    },
    getHumanizedRetourReason() {
      if (this.retourReason === 'defect') {
        return 'defect';
      }
      if (this.retourReason === 'wrongSize') {
        return 'verkeerde maat';
      }
      if (this.retourReason === 'maintenance') {
        return 'onderhoud';
      }
      return '';
    },
    prefillFields() {
      // if (this.retourStatus === 'maintenance') {
      //   this.defectDescription = 'Preventief onderhoud';
      // }
    },
    getIconForRetourStatus(retourStatus) {
      if (retourStatus === 'sent') {
        return 'mdi-email-fast-outline';
      }
      if (retourStatus === 'maintenance') {
        return 'mdi-wrench';
      }
      if (retourStatus === 'exchange') {
        if (this.retourReason === 'maintenance') {
          return 'mdi-wrench';
        }
        return 'mdi-swap-horizontal';
      }
      if (retourStatus === 'retour') {
        return 'mdi-arrow-right';
      }
      return '';
    },
    async patchWolkRetour(retour) {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/${this.$route.params.id}/retour`;
      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'patch',
        data: retour,
      });
    },
    sendToOrderSystem() {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/${this.$route.params.id}/retour/order`;
      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'post',
      });
    },
    sendMultipleToOrderSystem(wolkIds) {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/retour/order`;
      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'post',
        data: { wolkIds },
      });
    },
    setRetourVars() {
      this.defectSizeRadio = this.wolk.metaData.size;
      this.replaceType = this.wolk.type;
      this.retourReason = this.wolk.retour?.reason;
      if (this.wolk.retour?.status) {
        this.retourStatus = this.wolk.retour?.status;
        if (this.retourStatus === 'maintenance') {
          this.dialogScreen = 'describeDefect';
          this.defectDescription = this.wolk.retour?.defectDescription;
          this.defectOrigin = this.wolk.retour?.defectOrigin;
        }
        if (this.retourStatus === 'exchange' || this.retourStatus === 'retour') {
          this.dialogScreen = 'send';
        }
      }
      this.timestamp = this.wolk.retour?.timestamp;
      this.firstName = this.$store.state.myAccount.firstName;
      this.lastName = this.$store.state.myAccount.lastName;
      this.email = this.$store.state.myAccount.email;
      this.organizationId = this.$store.state.myAccount.userMetaData.organizationId;

      // set address vars
      this.toStreet = this.wolk.retour?.toAddress?.toStreet;
      this.toHouseNumber = this.wolk.retour?.toAddress?.toHouseNumber;
      this.toCity = this.wolk.retour?.toAddress?.toCity;
      this.toPostalCode = this.wolk.retour?.toAddress?.toPostalCode;
      this.toCountry = this.wolk.retour?.toAddress?.toCountry || 'NL';

      if (this.wolk.retour?.fromAddress) {
        this.fromStreet = this.wolk.retour?.fromAddress?.from_address_1;
        this.fromHouseNumber = this.wolk.retour?.fromAddress?.from_house_number;
        this.fromCity = this.wolk.retour?.fromAddress?.from_city;
        this.fromPostalCode = this.wolk.retour?.fromAddress?.from_postal_code;
        this.fromCountry = this.wolk.retour?.fromAddress?.from_country || 'NL';
      } else {
        this.fromStreet = this.toStreet;
        this.fromHouseNumber = this.toHouseNumber;
        this.fromCity = this.toCity;
        this.fromPostalCode = this.toPostalCode;
        this.fromCountry = this.toCountry;
      }
    },
    setDefectType(defectType) {
      this.defectType = defectType;
      if (defectType === 'C') {
        this.getNextDialogScreen();
      }
    },
    handleDefect(event) {
      this.defect = event.defect;
      this.defectHistory = event.history;
    },
    async sendWolks() {
      this.loadingRetour = true;
      const selectedWolks = this.selected;
      const wolkIds = selectedWolks.map((w) => w._id);

      const retours = [];
      for (let i = 0; i < selectedWolks.length; i += 1) {
        const selectedWolk = selectedWolks[i];
        const selectedId = selectedWolk._id;

        const retour = {
          timestamp: Date.now(),
          status: 'sent',
          user: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            organizationId: this.organizationId,
          },
          fromAddress: {
            from_address_1: this.fromStreet,
            from_house_number: this.fromHouseNumber,
            from_city: this.fromCity,
            from_postal_code: this.fromPostalCode,
            from_country: this.fromCountry,
          },
        };

        selectedWolk.retour = retour;

        retours.push({
          wolkId: selectedId,
          retour,
        });
      }
      this.dialog = false;
      let errorOccured = false;
      await this.patchWolkRetours({ retours });
      await this.createRetourParcel(wolkIds).then(() => {
        this.loadWolk();
        this.loadWolks();
      }).catch(async () => {
        // Error in order system => revert status
        await this.revertRetourStatus(retours);
        this.$store.commit('app/snackbarText', {
          text: 'Aanmaken label is mislukt',
          visible: true,
        });
        this.$store.commit('app/snackbarSuccess', false);
        errorOccured = true;
        this.loadingRetour = false;
      });
      if (!errorOccured) {
        await this.downloadLabelPdf(wolkIds[0]).then((res) => {
          // Download the label pdf automatically
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'label.pdf';
          link.click();
          URL.revokeObjectURL(link.href);

          this.$store.commit('app/snackbarSuccess', {
            text: 'Retour label gedownload en verstuurd via mail.',
            visible: true,
          });
        }).catch(async () => {
          // Error => revert status
          await this.revertRetourStatus(retours);
          await this.cancelParcel(wolkIds[0]);
          this.$store.commit('app/snackbarText', {
            text: 'Downloaden label is mislukt',
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
          errorOccured = true;
          this.loadingRetour = false;
        });
      }

      if (!errorOccured) {
        await this.sendMultipleToOrderSystem(wolkIds).catch(async () => {
          // Error in order system => revert status
          await this.cancelParcel(wolkIds[0]);
          await this.revertRetourStatus(retours);

          this.$store.commit('app/snackbarText', {
            text: 'Aanmaken label is mislukt',
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
          this.loadingRetour = false;
        });
      }
      this.loadingRetour = false;
    },
    async revertRetourStatus(retours) {
      const revertedRetours = retours.map((r) => ({
        ...r,
        retour: {
          ...r.retour,
          status: r.retour?.retourDescription ? 'retour' : 'exchange',
        },
      }));
      await this.patchWolkRetours({ retours: revertedRetours });
      this.retourStatus = this.wolk?.retour?.retourDescription ? 'retour' : 'exchange';
    },
    cancelParcel(wolkId) {
      return this.cancelParcels([wolkId]);
    },
    cancelParcels(wolkIds) {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/parcel/cancel`;
      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'post',
        data: wolkIds,
      });
    },
    patchWolkRetours(retours) {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/retour`;
      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'patch',
        data: retours,
      });
    },
    createRetourParcel(wolkIds) {
      const parcelItems = [];
      for (let i = 0; i < wolkIds.length; i += 1) {
        const wolkId = wolkIds[i];
        const [wolk] = this.wolks.filter((w) => w._id === wolkId);
        const type = wolk?.type;
        const sleevePublicId = wolk?.metaData?.sleevePublicId;

        if (!type || !sleevePublicId) {
          this.$store.commit('app/snackbarText', {
            text: 'Kon geen retour label aanmaken: Wolk heeft geen type of WolkNr',
            visible: true,
          });
          throw new Error('Can not create retour label: Wolk has no type or WolkNumber');
        }

        parcelItems.push({
          hs_code: type === 'Shorts' ? 62046110 : 42033000,
          weight: 0.350,
          quantity: 1,
          description: type,
          properties: {
            wolkNr: sleevePublicId,
          },
          value: 25,
        });
      }

      const body = {
        parcel: {
          // sender
          from_name: `${this.firstName} ${this.lastName}`,
          from_email: this.email,
          from_address_1: this.fromStreet,
          from_house_number: this.fromHouseNumber,
          from_city: this.fromCity,
          from_postal_code: this.fromPostalCode,
          from_country: this.fromCountry,

          // destination = Wolk Airbag
          name: 'Wolk Airbag',
          address: 'Anna van Hannoverstraat 4', // street + Nr
          city: 'Den Haag',
          postal_code: '2595 BJ',
          country: 'NL',
          shipment: {
            id: 1529, // PostNL
          },
          is_return: true,
          request_label: true,
          parcel_items: parcelItems,
        },

      };

      const url = `${config.VUE_APP_API_BASE_URL}/wolks/parcel`;
      return axios({
        url,
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data: body,
      }).then((response) => {
        this.$store.commit('app/snackbarSuccess', {
          text: 'Retour label aangemaakt.',
          visible: true,
        });
        return response;
      });
    },
    async setMaintenance() {
      this.dialog = false;
      this.retourStatus = 'maintenance';
      this.dialogScreen = 'describeDefect';
      this.retourReason = 'maintenance';

      await this.patchWolkRetour({
        timestamp: Date.now(),
        status: this.retourStatus,
        defectDescription: this.defectDescription,
        reason: 'maintenance',
        user: {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          organizationId: this.organizationId,
        },
      }).then(() => {
        this.loadWolks();
      });
    },
    openMaintenanceWindow() {
      this.dialogScreen = 'setMaintenance';
      this.defectDescription = 'Noodzakelijk onderhoud';
    },
    async cancelRetourButtonHandler() {
      this.loadingRetour = true;
      try {
        await this.cancelParcel(this.wolk?._id);
        const retour = {
          wolkId: this.wolk?._id,
          retour: this.wolk?.retour,
        };
        await this.revertRetourStatus([retour]).then(() => {
          this.loadWolk();
        });
      } catch (error) {
        let { message } = error;
        if (error.response) {
          message = error.response.data.message;
        }
        this.$store.commit('app/snackbarText', {
          text: `Kon verzending niet annuleren, probeer later opnieuw: ${message}`,
          error,
          visible: true,
        });
        this.loadingRetour = false;
      }
      this.loadingRetour = false;
    },
    async downloadLabelPdf(wolkId) {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/${wolkId}/retour/download`;
      return axios({
        url,
        responseType: 'arraybuffer',
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      });
    },
    getPreviousDefectQuestion() {
      this.defect = null;
      let length = null;
      if (this.defectType === 'A') {
        length = this.$refs.defectA.setPreviousQuestion();
        if (length === 0) {
          this.defectType = null;
        }
        return;
      }
      if (this.defectType === 'B') {
        length = this.$refs.defectB.setPreviousQuestion();
        if (length === 0) {
          this.defectType = null;
        }
        return;
      }

      this.getPreviousDialogScreen();
    },
    loadUser() {
      const url = `${config.VUE_APP_API_BASE_URL}/users/${this.$store.state.auth.id}`;
      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      }).then((response) => {
        const { user } = response.data;
        if (user) {
          this.user = user;
        }
      })
        .finally(() => {
          this.showDialog();
        });
    },
  },
};
</script>
