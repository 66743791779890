<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col>
          <div v-html="questions[currentQuestion]">
            {{ questions[currentQuestion] }}
          </div>
          <v-img
            v-if="[2, 8].includes(currentQuestion)"
            class="ma-4 mb-0"
            src="../../assets/slagpen.png"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            v-if="!binaryQuestion && binaryQuestion !== null"
            @click="setNextQuestion(null)"
          >
            Doorgaan
          </v-btn>
          <v-btn
            v-if="binaryQuestion"
            @click="setNextQuestion(true)"
          >
            Ja
          </v-btn>
          <v-btn
            v-if="binaryQuestion"
            class="ma-2"
            @click="setNextQuestion(false)"
          >
            Nee
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'DefectB',
  data() {
    return {
      defectType: 'B',
      show: false,
      actionQuestion: true,
      binaryQuestion: false,
      history: [],
      questions: {
        1: 'Verwijder de linker patroon. Zie '
          + '<a href="https://wolkairbag.com/instructies/" target="_blank">hier</a>'
          + ' hoe dat moet.',
        2: 'Kijk je tegen de slagpen aan als je in het gaatje kijkt van de linker patroon?',
        3: 'Plaats een nieuw patroon. <br> Klik '
          + '<a href="https://www.youtube.com/watch?v=0xb0VtZaenI" target="_blank">hier</a>'
          + ' om te zien hoe je de patroon vervangt bij een Belt. <br> Klik '
          + '<a href="https://www.youtube.com/watch?v=hNjvdFR5u4M" target="_blank">hier</a>'
          + ' om te zien hoe je de patroon vervangt bij een Shorts.',
        4: 'Sluit het klittenband (indien Belt) en beweeg de Wolk.',
        5: 'Brandt het lampje continu rood?',
        6: 'De patronen in de Wolk zijn in orde. Indien het lampje van de Wolk groen brandt dan kan '
          + 'de Wolk terug ingezet worden. '
          + '<i>Indien het lampje andere kleur brandt zie dan <a href="https://wolkairbag.com/wp-content/uploads/2022/01/Wolk_Instructies_Belt_Lampje.pdf" target="_blank">hier</a> wat dit betekent</i>',
        7: 'Verwijder de rechter patroon. Zie '
          + '<a href="https://wolkairbag.com/instructies/" target="_blank">hier</a>'
          + ' hoe dat moet.',
        8: 'Kijk je tegen de slagpen aan als je in het gaatje kijkt van de rechterpatroon?',
        9: 'De Wolk is defect. Klik op doorgaan om deze Wolk defect te melden.',
        10: 'Plaats een nieuw patroon',
        11: 'Sluit het klittenband (indien Belt) en beweeg de Wolk.',
        12: 'Gaat het lampje (even groen of groen/oranje branden?',
        13: 'De Wolk is defect. Klik op doorgaan om deze Wolk defect te melden.',
        14: 'De patronen in de Wolk zijn in orde. Indien het lampje van de Wolk groen of '
          + 'groen/oranje brandt kan de Wolk weer ingezet worden.',
        15: 'Plaats de linker patroon terug.',
      },
      currentQuestion: 1,
      previousQuestion: 1,
      previousAnswer: null,
    };
  },
  methods: {
    setNextQuestion(answeredYes) {
      this.history.push({
        question: this.currentQuestion,
        answer: answeredYes,
      });
      if (this.currentQuestion === 1) {
        this.currentQuestion = 2;
        this.binaryQuestion = true;
        return;
      }
      if (this.currentQuestion === 2) {
        this.binaryQuestion = false;
        if (answeredYes) {
          this.currentQuestion = 3;
          return;
        }
        this.currentQuestion = 15;
        return;
      }
      if (this.currentQuestion === 3) {
        this.currentQuestion = 4;
        return;
      }
      if (this.currentQuestion === 4) {
        this.currentQuestion = 5;
        this.binaryQuestion = true;
        return;
      }
      if (this.currentQuestion === 5) {
        if (answeredYes) {
          this.currentQuestion = 7;
          this.binaryQuestion = false;
          return;
        }
        this.currentQuestion = 6;
        this.$emit('defect', {
          history: this.history,
          defect: false,
        });
        this.binaryQuestion = null;
        return;
      }
      if (this.currentQuestion === 7) {
        this.currentQuestion = 8;
        this.binaryQuestion = true;
        return;
      }
      if (this.currentQuestion === 8) {
        if (answeredYes) {
          this.currentQuestion = 10;
          this.binaryQuestion = false;
          return;
        }
        this.currentQuestion = 13;
        this.binaryQuestion = null;
        this.$emit('defect', {
          history: this.history,
          defect: true,
        });
        return;
      }
      if (this.currentQuestion === 10) {
        this.currentQuestion = 11;
        return;
      }
      if (this.currentQuestion === 11) {
        this.currentQuestion = 12;
        this.binaryQuestion = true;
        return;
      }
      if (this.currentQuestion === 12) {
        this.binaryQuestion = null;
        if (answeredYes) {
          this.currentQuestion = 14;
          this.$emit('defect', {
            history: this.history,
            defect: false,
          });
          return;
        }
        this.currentQuestion = 13;
        this.$emit('defect', {
          history: this.history,
          defect: true,
        });
        return;
      }
      if (this.currentQuestion === 15) {
        this.currentQuestion = 7;
        this.binaryQuestion = false;
      }
    },
    setPreviousQuestion() {
      const { length } = this.history;
      const prevQuestion = this.history.pop();
      if (!prevQuestion) {
        return 0;
      }
      const { question, answer } = prevQuestion;
      this.currentQuestion = question;

      if (answer == null) {
        this.binaryQuestion = false;
        return length;
      }

      this.binaryQuestion = true;
      return length;
    },
  },
};

</script>
