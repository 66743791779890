<template>
  <div>
    <v-switch
      v-model="visible"
      label="Locatie aanpassen"
    />

    <v-alert
      v-if="visible"
      text
      color="info"
    >
      <div>
        Kies de locatie waar de Wolk wordt ingezet.
      </div>

      <v-divider
        class="my-4 info"
        style="opacity: 0.22"
      />

      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-card>
            <v-list
              v-if="locations.length > 0"
              dense
            >
              <v-list-item-group
                v-model="selectedLocationIndex"
              >
                <v-list-item
                  v-for="location in locations"
                  :key="location._id"
                >
                  <v-list-item-content>
                    {{ location.title }}
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-card-text v-else>
              No locations to select, please create them first.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            outlined
            color="info"
            class="mr-2"
            @click="visible = false"
          >
            Annuleer
          </v-btn>

          <v-btn
            :disabled="!newLocation"
            outlined
            color="success"
            @click="setLocation"
          >
            Zet op locatie
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="currentLocationId">
        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            outlined
            color="error"
            @click="removeFromLocation"
          >
            Verwijder uit locatie
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SleeveAssignmentsChangeLocation',
  props: {
    wolkId: {
      type: String,
      required: true,
    },
    sleeveAssignmentId: {
      type: String,
      required: true,
    },
    locations: {
      type: Array,
      default: () => [],
    },
    currentLocationId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      newLocation: null,
    };
  },
  computed: {
    currentLocationIndex() {
      return this.locations.findIndex((l) => l._id === this.currentLocationId);
    },
    selectedLocationIndex: {
      get() {
        if (this.newLocation) {
          return this.locations.findIndex((l) => l._id === this.newLocation._id);
        }

        return this.currentLocationIndex;
      },
      set(index) {
        if (this.currentLocationIndex && this.currentLocationIndex === index) {
          this.newLocation = null;
          return;
        }

        this.newLocation = this.locations[index];
      },
    },
    selectedLocation() {
      return this.locations[this.selectedLocationIndex];
    },
  },
  methods: {
    setLocation() {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/${this.wolkId}/location`;
      const headers = { Authorization: `Bearer ${this.$store.state.auth.jwt}` };
      const data = { locationId: this.newLocation._id };

      axios({
        url,
        method: 'post',
        headers,
        data,
      })
        .then(() => {
          this.$emit('update:wolk');
        });
    },
    removeFromLocation() {
      this.loading = true;

      const url = `${config.VUE_APP_API_BASE_URL}/wolks/${this.wolkId}/location`;
      const headers = { Authorization: `Bearer ${this.$store.state.auth.jwt}` };

      axios({
        url,
        method: 'delete',
        headers,
      })
        .then(() => {
          this.$emit('update:wolk');
        });
    },
  },
};
</script>
