<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col>
          <div v-html="questions[currentQuestion]">
            {{ questions[currentQuestion] }}
          </div>
          <v-img
            v-if="[6, 8].includes(currentQuestion)"
            max-width="200"
            class="ma-4 mb-0"
            src="../../assets/charger.png"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            v-if="!binaryQuestion && binaryQuestion !== null"
            @click="setNextQuestion(null)"
          >
            Doorgaan
          </v-btn>
          <v-btn
            v-if="binaryQuestion"
            @click="setNextQuestion(true)"
          >
            Ja
          </v-btn>
          <v-btn
            v-if="binaryQuestion"
            class="ma-2"
            @click="setNextQuestion(false)"
          >
            Nee
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'DefectA',
  data() {
    return {
      defectType: 'A',
      show: false,
      binaryQuestion: true,
      history: [],
      questions: {
        1: 'Leg de Wolk aan de lader. Brandt het lampje (even)?',
        2: 'Laad de Wolk minstens 15 minuten op en keer terug naar dit scherm. <br> Als je dit al hebt gedaan, klik dan op doorgaan.',
        3: 'Haal de Wolk na 15min laden van de oplader, sluit het klittenband (indien Belt) en beweeg de Wolk.'
          + ' Gaat het lampje (even) branden?',
        4: 'De Wolk is niet defect. Indien het lampje groen brandt kan de Wolk weer ingezet worden. '
          + 'Indien het lampje een andere kleur brandt, zie dan '
          + '<a href="https://wolkairbag.com/wp-content/uploads/2022/01/Wolk_Instructies_Belt_Lampje.pdf" target="_blank">hier</a> wat dit betekent.',
        5: 'De Wolk is defect. Klik op doorgaan om deze Wolk defect te melden.',
        6: 'Gebruik je een magneetlader? (zie plaatje)',
        7: 'De Wolk is defect. Klik op doorgaan om deze Wolk defect te melden.',
        8: 'Gebruik een andere rode oplaadkabel',
      },
      currentQuestion: 1,
      previousQuestion: 1,
      previousAnswer: null,
    };
  },
  methods: {
    setNextQuestion2(answeredYes) {
      this.history.push({
        question: this.currentQuestion,
        answer: answeredYes,
      });
      if (this.currentQuestion === 1) {
        this.currentQuestion = 2;
        this.binaryQuestion = true;
        return;
      }
      if (this.currentQuestion === 2) {
        if (answeredYes) {
          this.currentQuestion = 3;
          this.binaryQuestion = false;
          return;
        }
        this.currentQuestion = 4;
        this.binaryQuestion = true;
        return;
      }
      if (this.currentQuestion === 3) {
        this.currentQuestion = 5;
        this.binaryQuestion = false;
        return;
      }
      if (this.currentQuestion === 5) {
        this.currentQuestion = 6;
        this.binaryQuestion = true;
        return;
      }
      if (this.currentQuestion === 6) {
        if (answeredYes) {
          this.currentQuestion = 7;
          this.binaryQuestion = null;
          this.$emit('defect', {
            history: this.history,
            defect: false,
          });
          return;
        }
        this.currentQuestion = 8;
        this.binaryQuestion = null;
        this.$emit('defect', {
          history: this.history,
          defect: true,
        });
      }
      if (this.currentQuestion === 4) {
        if (answeredYes) {
          this.binaryQuestion = false;
          this.currentQuestion = 10;
          return;
        }
        this.currentQuestion = 8;
        this.binaryQuestion = null;
        this.$emit('defect', {
          history: this.history,
          defect: true,
        });
        return;
      }
      if (this.currentQuestion === 12) {
        this.currentQuestion = 1;
        return;
      }
      if (this.currentQuestion === 10) {
        this.currentQuestion = 11;
        this.binaryQuestion = true;
        return;
      }
      if (this.currentQuestion === 11) {
        if (answeredYes) {
          this.currentQuestion = 3;
          this.binaryQuestion = false;
          return;
        }
        this.currentQuestion = 8;
        this.binaryQuestion = null;
        this.$emit('defect', {
          history: this.history,
          defect: true,
        });
      }
    },
    setNextQuestion(answeredYes) {
      this.history.push({
        question: this.currentQuestion,
        answer: answeredYes,
      });
      if (this.currentQuestion === 1) {
        if (answeredYes) {
          this.currentQuestion = 2;
          this.binaryQuestion = false;
          return;
        }
        this.currentQuestion = 6;
        this.binaryQuestion = true;
        return;
      }
      if (this.currentQuestion === 2) {
        this.currentQuestion = 3;
        this.binaryQuestion = true;
        return;
      }
      if (this.currentQuestion === 3) {
        console.log(answeredYes);
        console.log(this.currentQuestion);
        if (answeredYes) {
          this.currentQuestion = 4;
          this.binaryQuestion = null;
          this.$emit('defect', {
            history: this.history,
            defect: false,
          });
          return;
        }
        this.currentQuestion = 5;
        this.binaryQuestion = null;
        this.$emit('defect', {
          history: this.history,
          defect: true,
        });
        return;
      }
      if (this.currentQuestion === 6) {
        if (answeredYes) {
          this.currentQuestion = 8;
          this.binaryQuestion = false;
          return;
        }
        this.currentQuestion = 7;
        this.binaryQuestion = null;
        this.$emit('defect', {
          history: this.history,
          defect: true,
        });
      }
      if (this.currentQuestion === 8) {
        this.currentQuestion = 2;
        this.binaryQuestion = false;
      }
    },
    setPreviousQuestion() {
      const { length } = this.history;
      const prevQuestion = this.history.pop();
      if (!prevQuestion) {
        return 0;
      }
      const { question, answer } = prevQuestion;
      this.currentQuestion = question;

      if (answer == null) {
        this.binaryQuestion = false;
        return length;
      }

      this.binaryQuestion = true;
      return length;
    },
  },
};

</script>
