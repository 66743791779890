<template>
  <div v-if="sleeveAssignmentId && organizationId">
    <v-switch
      v-model="visible"
      label="Meldingen aanpassen"
    />

    <v-alert
      v-if="visible"
      text
      color="info"
    >
      <div>
        <p>
          Stel hieronder de telefoonnummers voor activatie meldingen in.
        </p>

        <p>
          Na een 06-nummer toegevoegd te hebben kan deze aangevinkt worden
          zodat nieuwe activaties via SMS verzonden zullen worden.
        </p>
      </div>

      <v-divider
        class="my-4 info"
        style="opacity: 0.22"
      />

      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-card>
            <v-list
              v-if="contactRoutes.length > 0"
              dense
            >
              <v-list-item
                v-for="contactRoute in contactRoutes"
                :key="contactRoute._id"
                :disabled="loadingContactRouteIds.includes(contactRoute._id)"
                @click="
                  () => hasSubscription(contactRoute._id)
                    ? stopNotificationSubscription(contactRoute._id)
                    : createNotificationSubscription(contactRoute._id)
                "
              >
                <v-list-item-action>
                  <v-icon :color="hasSubscription(contactRoute._id) ? 'success' : 'grey'">
                    {{
                      hasSubscription(contactRoute._id)
                        ? $vuetify.icons.values.checkboxOn
                        : $vuetify.icons.values.checkboxOff
                    }}
                  </v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  {{ humanizePhoneNumber(contactRoute.value) }}
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon>
                    mdi-cellphone-message
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>

            <v-divider v-if="contactRoutes.length > 0" />

            <v-card-text>
              <v-text-field
                v-model="newPhoneNumber"
                label="Nieuw telefoonnummer"
                prefix="+31 6"
                maxlength="8"
              />

              <v-alert
                v-if="showInvalidNewPhoneNumberMessage"
                border="bottom"
                colored-border
                type="warning"
                elevation="2"
              >
                Vul alleen de nummers van het 06 nummer na "+316" in
              </v-alert>

              <v-btn
                outlined
                color="success"
                class="mr-2"
                @click="createContactRoute"
              >
                Voeg toe
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            outlined
            color="info"
            class="mr-2"
            @click="visible = false"
          >
            Sluiten
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from 'axios';

const onlyContainsNumbers = (input) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const char of input) {
    if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(char)) {
      return false;
    }
  }

  return true;
};

export default {
  name: 'SleeveAssignmentsChangeNotificationSubscriptions',
  props: {
    sleeveAssignmentId: {
      type: String,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      contactRoutesLoading: false,
      notificationSubscriptionsLoading: false,
      contactRoutes: [],
      notificationSubscriptions: [],
      newPhoneNumber: '',
      showInvalidNewPhoneNumberMessage: false,
      loadingContactRouteIds: [],
    };
  },
  computed: {
    loading() {
      return this.contactRoutesLoading || this.notificationSubscriptionsLoading;
    },
    newPhoneNumberValid() {
      if (typeof this.newPhoneNumber !== 'string') {
        return false;
      }

      if (this.newPhoneNumber.length !== 8) {
        return false;
      }

      if (!onlyContainsNumbers(this.newPhoneNumber)) {
        return false;
      }

      return true;
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        return;
      }

      this.loadAll();
    },
    newPhoneNumber(val, oldVal) {
      const charsLengtDiff = val.length - oldVal.length;

      this.setShowInvalidNewPhoneNumberMessage(charsLengtDiff, val);
    },
  },
  methods: {
    eelco(e) {
      console.log({ e });
    },
    loadAll() {
      this.loadContactRoutes();
      this.loadNotificationSubscriptions();
    },
    humanizePhoneNumber(value) {
      return `${value.substring(0, 3)} ${value.substring(3, 4)} ${value.substring(4, 12)}`;
    },
    hasSubscription(contactRouteId) {
      const foundNotificationSubscription = this
        .notificationSubscriptions
        .find((ns) => ns.references && ns.references.contactRouteId === contactRouteId);

      return foundNotificationSubscription !== undefined;
    },
    toggleContactRoute(contactRouteId) {
      console.log(`Changing contactRouteId ${contactRouteId}`);
    },
    setShowInvalidNewPhoneNumberMessage(charsLengtDiff, newPhoneNumber) {
      if (charsLengtDiff === 1) {
        // Someone is typing

        if (typeof this.newPhoneNumber !== 'string') {
          this.showInvalidNewPhoneNumberMessage = true;
          return;
        }

        if (!onlyContainsNumbers(newPhoneNumber)) {
          this.showInvalidNewPhoneNumberMessage = true;
          return;
        }

        this.showInvalidNewPhoneNumberMessage = false;
        return;
      }

      if (charsLengtDiff === -1) {
        // Someone is back-spacing

        if (typeof this.newPhoneNumber !== 'string') {
          this.showInvalidNewPhoneNumberMessage = true;
          return;
        }

        if (!onlyContainsNumbers(newPhoneNumber)) {
          this.showInvalidNewPhoneNumberMessage = true;
          return;
        }

        this.showInvalidNewPhoneNumberMessage = false;
        return;
      }

      this.showInvalidNewPhoneNumberMessage = this.newPhoneNumberValid;
    },
    loadContactRoutes() {
      this.contactRoutesLoading = true;

      const url = `${config.VUE_APP_API_BASE_URL}/contact-routes`;
      const headers = { Authorization: `Bearer ${this.$store.state.auth.jwt}` };
      const params = { organizationId: this.organizationId, medium: 'sms' };

      axios({
        url,
        method: 'get',
        headers,
        params,
      })
        .then((response) => {
          if (response.data && response.data.contactRoutes) {
            this.contactRoutes = response.data.contactRoutes;
          }
        })
        .then(() => {
          this.contactRoutesLoading = false;
        })
        .catch(() => {
          this.contactRoutesLoading = false;
        });
    },
    createContactRoute() {
      if (!this.newPhoneNumberValid) {
        this.showInvalidNewPhoneNumberMessage = true;
        return;
      }

      const url = `${config.VUE_APP_API_BASE_URL}/contact-routes`;
      const headers = { Authorization: `Bearer ${this.$store.state.auth.jwt}` };
      const data = {
        organizationId: this.organizationId,
        medium: 'sms',
        value: `+316${this.newPhoneNumber}`,
      };

      axios({
        url,
        method: 'post',
        headers,
        data,
      })
        .then((response) => {
          if (response && response.data && response.data.insertedId) {
            // Send created message

            this.newPhoneNumber = '';
            this.showInvalidNewPhoneNumberMessage = false;
          }
        })
        .then(() => {
          this.contactRoutesLoading = false;

          this.loadAll();
        })
        .catch(() => {
          this.contactRoutesLoading = false;
        });
    },
    loadNotificationSubscriptions() {
      this.notificationSubscriptionsLoading = true;

      const url = `${config.VUE_APP_API_BASE_URL}/notification-subscriptions`;
      const headers = { Authorization: `Bearer ${this.$store.state.auth.jwt}` };
      const params = { sleeveAssignmentId: this.sleeveAssignmentId };

      axios({
        url,
        method: 'get',
        headers,
        params,
      })
        .then((response) => {
          if (response.data && response.data.notificationSubscriptions) {
            this.notificationSubscriptions = response.data.notificationSubscriptions;
          }
        })
        .then(() => {
          this.notificationSubscriptionsLoading = false;
        })
        .catch(() => {
          this.notificationSubscriptionsLoading = false;
        });
    },
    createNotificationSubscription(contactRouteId) {
      this.loadingContactRouteIds = [...this.loadingContactRouteIds, contactRouteId];

      const url = `${config.VUE_APP_API_BASE_URL}/notification-subscriptions`;
      const headers = { Authorization: `Bearer ${this.$store.state.auth.jwt}` };
      const data = {
        sleeveAssignmentId: this.sleeveAssignmentId,
        contactRouteId,
      };

      axios({
        url,
        method: 'post',
        headers,
        data,
      })
        .then(() => {
          this.notificationSubscriptionsLoading = false;

          this.loadingContactRouteIds = this
            .loadingContactRouteIds
            .filter((crid) => crid !== contactRouteId);
        })
        .catch(() => {
          this.notificationSubscriptionsLoading = false;

          this.loadingContactRouteIds = this
            .loadingContactRouteIds
            .filter((crid) => crid !== contactRouteId);
        })
        .then(() => {
          this.loadAll();
        });
    },
    stopNotificationSubscription(contactRouteId) {
      this.loadingContactRouteIds = [...this.loadingContactRouteIds, contactRouteId];

      const foundNotificationSubscription = this
        .notificationSubscriptions
        .find((ns) => ns.references && ns.references.contactRouteId === contactRouteId);

      if (!foundNotificationSubscription) {
        this.loadingContactRouteIds = this
          .loadingContactRouteIds
          .filter((crid) => crid !== contactRouteId);

        // Show error to customer

        return;
      }

      const url = `${config.VUE_APP_API_BASE_URL}/notification-subscriptions/${foundNotificationSubscription._id}`;
      const headers = { Authorization: `Bearer ${this.$store.state.auth.jwt}` };
      const data = { stopped: true };

      axios({
        url,
        method: 'patch',
        headers,
        data,
      })
        .then(() => {
          this.notificationSubscriptionsLoading = false;

          this.loadingContactRouteIds = this
            .loadingContactRouteIds
            .filter((crid) => crid !== contactRouteId);
        })
        .catch(() => {
          this.notificationSubscriptionsLoading = false;

          this.loadingContactRouteIds = this
            .loadingContactRouteIds
            .filter((crid) => crid !== contactRouteId);
        })
        .then(() => {
          this.loadAll();
        });
    },
  },
};
</script>
